import { Title } from '@/components/common/Title';
import { Section } from '@/components/layout/Section';

import type { SanityStandaloneHeadingType } from '@/types/sanity';

export interface StandaloneTitleProps extends SanityStandaloneHeadingType {
  size?: 'xl' | 'lg' | 'md' | 'sm';
}

export const StandaloneTitle = ({
  tag,
  heading,
  headingLevel,
  desktopAlignment,
  mobileAlignment,
  caption,
  subheading,
  paddingAdjustment = 'default',
  blockSpacing,
  backgroundColour,
  size,
}: StandaloneTitleProps) => {
  const sizeMap = {
    1: 'xl',
    2: 'lg',
    3: 'md',
    4: 'sm',
  };

  const transformedSize = size || sizeMap[headingLevel];

  return (
    <Section
      backgroundColour={backgroundColour?.token}
      verticalPadding={paddingAdjustment}
      spacing={blockSpacing}
      className="standalone-heading"
    >
      <Title
        badge={tag?.tagText}
        caption={caption}
        heading={heading}
        subheading={subheading}
        desktopAlignment={desktopAlignment}
        mobileAlignment={mobileAlignment}
        hidden={false}
        variant="hero"
        size={transformedSize}
        badgeColour={tag?.tagColour}
      />
    </Section>
  );
};
